<template>
  <div>
    <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
    <el-row v-else>
      <el-col class="flag-list" v-for="(m, index) in list">
        <router-link :to="{ path: '/fwdes', query: { id: m.id } }">
          <el-skeleton :loading="loading" animated :count="1">
            <template slot="template">
              <el-skeleton-item variant="image" class="img" />
              <div class="content">
                <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                <el-skeleton-item variant="text" style="width: 50%;" />
              </div>
              <div class="bottom flex-bt">
                <el-skeleton-item variant="text" style="width: 30%;" />
                <el-skeleton-item variant="text" style="width: 30%;" />
              </div>
            </template>
            <template>
              <el-image class="img" :src="rImg(m.image || m.head_portrait)" fit="cover">
                <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
              </el-image>
              <div class="content">
                <div class="title ellipsis-2">{{ m.title }}</div>
                <div class="flex-w tag-box">
                  <span class="tag">{{ m.type == 1 ? '线下' : '线上' }}</span>
                  <span class="tag" v-for="(t, tkey) in m.cates">{{ t }}</span>
                </div>
                <!-- <p class="area">
                  <i class="iconfont icon-zhiyuandidian6"></i> 12km
                </p> -->
              </div>
              <div class="bottom flex-bt">
                <div class="user">
                  <img :src="rImg(m.head_portrait)" :onerror="txImg" class="tx" alt="" />
                  <span class="name ellipsis-1">{{ m.name }}</span>
                </div>
                <div class=" flex">
                  <i class="iconfont icon-jinbi"></i>
                  <!-- <span class="see">￥{{ m.type == 1 ? m.price + '/小时' : m.price }}</span> -->
                  <span class="see">￥{{ m.price }}/小时</span>
                </div>
              </div>
            </template>
          </el-skeleton>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'fwList',
  components: {},
  props: ['list'],
  data() {
    return {
      loading: false,
      id: ''
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    this.id = this.$route.query.id;
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {},
  computed: {
    // 计算属性
  },
  watch: {
    // 监听
  }
};
</script>

<style lang="less" scoped>
@import '@assets/less/flag.less';
.tag {
  background: transparent;
  color: #fd5621;
  border: 1px solid;
  margin-right: 5px;
}
</style>
