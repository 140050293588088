<template>
  <div class="wrap">
    <skillNav :list="nav" @setNav="setNav"></skillNav>
    <fwList :list="list"></fwList>
  </div>
</template>

<script>
  import skillNav from '@components/common/snav';
  import fwList from '@components/common/fuwu-list';
  import {
    mapGetters,
    mapMutations
  } from 'vuex';
  export default {
    components: {
      skillNav,
      fwList
    },
    data() {
      return {
        nav: [],
        list: [],
        navId: 0
      };
    },
    created() {
      // 在组件实例处理完所有与状态相关的选项后调用。
      this.setMeta("热浪-服务", 1);
    },
    mounted() {
      // 在组件被挂载之后调用。
      this.getNav();

    },
    methods: {
      // 切换栏目
      setNav(m) {
        if (m.child && m.child.length > 0) {
          let s = [];
          m.child.forEach((v, i) => {
            s.push(v.id);
          });
          this.navId = s.join(',');
        } else {
          this.navId = m.id;
        }
        this.getData(1);
      },
      // 获取服务的子栏目
      async getNav() {
        let {
          data
        } = await this.$y_ajax('api/Labels/lists');
        this.nav = data;
        this.setNav(data[0]);
      },
      // 获取列表
      async getData(first) {
        let {
          city
        } = this.$y_getKey('cityInfo') || {};
        let param = {
          category: this.navId, // 分类
          city: city
        };
        if (first) {
          param.page = 1;
        }
        let {
          list,
          total
        } = await this.$y_list('api/service/allul', this.list, param);
        this.list = list;
      }
    },
    computed: {
      // 计算属性
      ...mapGetters(['s_getUser', 's_getCity'])
    },
    watch: {
      // 监听
      s_getUser() {
        this.getData(1);
      },
      s_getCity() {
        this.getData(1);
      }
    }
  };
</script>

<style lang="less" scoped></style>